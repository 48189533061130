<template>
  <v-container flat dense class="px-8 pt-5 pb-12">
    <v-row class="px-8">
      <v-col cols="6" class="pt-5 px-3">
        <v-card flat style="max-height:150px" v-if="company.logo">
            <img class="text-capitalize" :src="company.logo" :alt="company.name" width="70%">
        </v-card>
        <span class="text-h6 font-weight-medium" v-else>
          {{company.name}}
        </span>
      </v-col>
      <v-col cols="6" class="d-flex flex-column align-end justify-center" v-if="property">
        <span class="subtitle-1 font-weight-medium text-capitalize">{{property.name}}</span>
        <span class="subtitle-2" v-if="property.Emails && property.Emails.length">{{property.Emails[0].email}}</span>
        <span class="subtitle-2" v-if="property.Phones && property.Phones.length">{{property.Phones[0].phone | formatPhone}}</span>
        <span class="subtitle-2" v-if="property.Address">{{ property.Address | formatAddress(',', true) }} </span>
      </v-col>
    </v-row>
    <v-row class="mx-0 mt-6 px-5">
      <v-col cols="6" class="align-end d-flex px-0">
         <span class="hb-header-font-size-receipt font-weight-medium ml-3" v-if="payment.isAuction">Auction Receipt</span>
         <span class="hb-header-font-size-receipt font-weight-medium ml-3" v-else>Customer Receipt</span>
      </v-col>
      <v-col cols="6" class="align-center d-flex justify-end text-h6">
        <span class="pr-3">Payment Date:</span>{{payment.date | formatDateCustom('MMM DD, YYYY')}}
      </v-col>
    </v-row>
    <v-divider class="mx-4"></v-divider>
    <view-payment-content :invoices="invoices" :is-guest-user="isGuestUser" :payment="payment"></view-payment-content>

    <v-container v-if="fromOnboarding" class="mx-0 mb-6 pa-0">
      <v-divider class="mx-4 mt-3"></v-divider>

      <v-row class="mx-8 pt-6 pb-4"><strong class="title">What's next?</strong></v-row>
      <v-row class="mx-8">
        Call your property manager as soon as possible to to complete your reservation <span v-if="property.Phones.length">{{property.Phones[0].phone | formatPhone}} </span><br /><br />
      </v-row>
      <v-row class="mx-8 mt-2">
        Show up at your property on or before your move-in date.
      </v-row>
      <v-row class="mx-8 mt-2">
        If you decide you need a different unit, we can easily make that change for you.
      </v-row>
      <v-row class="mx-8 mt-2">
        Bring your government-issued ID to complete your rental.
      </v-row>
      <v-row class="mx-8 mt-2">
        A lock is required and is available at your property.
      </v-row>
      <v-row class="mx-8 mt-2">
        Moving supplies are available at the property.
      </v-row>

    </v-container>

    <!-- <v-divider class="mx-4 mt-4"></v-divider>

    <v-row class="mx-4 py-4"><strong class="pr-1">Do you have any questions?</strong> Call us <span v-if="property.Phones && property.Phones.length" class="ml-1">at {{property.Phones[0].phone | formatPhone }}</span></v-row> -->

  </v-container>

</template>


<script type="text/babel">

  import ViewPaymentContent from './ViewPaymentContent.vue'
  import Status from '../includes/Messages.vue';
  import Loader from '../assets/CircleSpinner.vue';
  import api from '../../assets/api.js';
  import { mapGetters } from 'vuex';
import methods from '../../assets/api/methods';
  export default {
      name: "ViewPayment",
      data() {
          return {
              fromOnboarding: false,
          }
      },
      computed:{
          ...mapGetters({
              isAdmin: 'authenticationStore/isAdmin'
          }),
          isGuestUser() {
            return this.payment && this.payment.Contact?.email && this.payment.Contact?.email === 'posguestuser@tenantinc.com' ? true : false;
          }
      },
      components: {
        ViewPaymentContent,
        Status,
        Loader
      },
      props: ['company', 'property', 'invoices', 'payment'],
      methods:{

      }
  }

</script>
<style scoped>
  .hb-header-font-size-receipt {
    font-size: 30px;
  }
</style>
